// Copyright © 2025 DIGITAL TRAINING DIARY LTD. All Rights Reserved.
// Unauthorized use of this code is strictly prohibited.

import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataProvider } from "./DataContext";
import { ThemeProvider } from '@mui/material/styles';
import customMuiTheme from "./components/themes/CustomMuiTheme.js";
import Layout from "./pages/Layout";
import UserHome from "./pages/UserHome";
import DiarySelection from "./pages/diary/DiarySelection";
import Diary from "./pages/diary/Diary";
import Goals from "./pages/goals/Goals";
import Goal from "./pages/goals/Goal";
import Drills from "./pages/drills/Drills";
import Routines from "./pages/routines/Routines";
import Skills from "./pages/skills/Skills";
import Events from "./pages/events/Events";
import Conditioning from "./pages/conditioning/Conditioning";
import ConditioningSession from "./pages/conditioning/components/standard/ConditioningSession";
import ConditioningTestSession from "./pages/conditioning/components/testing/ConditioningTestSession";
import Resources from "./pages/resources/Resources";
import Dashboard from "./pages/insights/Insights";
import Settings from "./pages/settings/Settings";
import NoPage from "./pages/NoPage"
import './reset.css';
import './App.css';
import axios from "axios";
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
//import { Authenticator } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
//import NoSubscription from "./pages/NoSubscription.js";
import SelectSubscription from "./pages/SelectSubscription.js";
Amplify.configure(awsExports);

function App({ user, signOut }) {

  const [isCheckingSubscription, setIsCheckingSubscription] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const fetchJwtToken = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.idToken;
        localStorage.setItem('jwtToken', token);
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;

        checkSubscription();
        
      } catch (error) {
        console.error('Error checking subscription status:', error);
      }
    };

    const checkSubscription = async () => {
      try {      
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}/payments/checkout-session`
        );
        const { subscriptionStatus } = response.data;

        setIsCheckingSubscription(false);
        if (subscriptionStatus === 'active') {
          setIsSubscribed(true);
        }

      } catch (error) {
        setIsCheckingSubscription(false);
        setIsSubscribed(false);
      }
    };

    fetchJwtToken();
  }, [user]);
  
  // if (true) {
  //   return <ThemeProvider theme={customMuiTheme}><SelectSubscription signOut={signOut}></SelectSubscription></ThemeProvider>
  // }

  if (isCheckingSubscription) {
    return <p>Loading...</p>;
  }

  if (!isSubscribed) {
    //return <NoSubscription signOut={signOut}></NoSubscription>;
    return <ThemeProvider theme={customMuiTheme}><SelectSubscription signOut={signOut}></SelectSubscription></ThemeProvider>
  }

  return (
      <DataProvider>
        <div className="wrapper-main">
          <ThemeProvider theme={customMuiTheme}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index path="/" element={<UserHome user={user} />} />
                  <Route path="/diary" element={<DiarySelection user={user} />} />
                  <Route path="/diary-entry" element={<Diary user={user} />} />
                  <Route path="/goals" element={<Goals user={user} />} />
                  <Route path="/goal" element={<Goal user={user} />} />
                  <Route path="/drills" element={<Drills user={user} />} />
                  <Route path="/routines" element={<Routines user={user} />} />
                  <Route path="/events" element={<Events user={user} />} />
                  <Route path="/resources" element={<Resources />} />
                  <Route path="/skills" element={<Skills user={user} />} />
                  <Route path="/conditioning" element={<Conditioning user={user} />} /> 
                  <Route path="/conditioning/session" element={<ConditioningSession user={user} />} /> 
                  <Route path="/conditioning/test-session" element={<ConditioningTestSession user={user} />} /> 
                  <Route path="/data" element={<Dashboard user={user} />} />
                  <Route path="/settings" element={<Settings userId={user.userId} signOut={signOut} />} />
                  <Route path="*" element={<NoPage />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </div>
      </DataProvider>
  );
}
export default withAuthenticator(App);



// function App() {
//   return (
//     <div className="wrapper-main">
//       <Authenticator>
//           {({ signOut, user }) => (
//                <DataProvider>
//               <BrowserRouter>
//                 <Routes>
//                   <Route path="/" element={<Layout />}>
//                     <Route index path="/" element={<UserHome user={user} signOut={signOut} />} />
//                     <Route path="/mood" element={<MoodScreen defaultSlide={3} />} />
//                     <Route path="/diary" element={<DiarySelection user={user} />} />
//                     <Route path="/diary-entry/:session_id" element={<Diary user={user} />} />
//                     <Route path="/drills" element={<Drills user={user} />} />
//                     <Route path="/routines" element={<Routines user={user} />} />
//                     <Route path="/resources" element={<Resources />} />
//                     <Route path="*" element={<NoPage />} />
//                   </Route>
//                 </Routes>
//               </BrowserRouter>
//           </DataProvider>
//           )}
//       </Authenticator>
//     </div>
//   );
// }

// export default App;