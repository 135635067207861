import { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { IconButton, Box, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import LoadingMessage from '../../../../components/LoadingMessage.js';
import DeleteConfirmationBox from '../../../../components/DeleteConfirmationBox.js';
import ConditioningEntry from './ConditioningEntry.js';
import AddConditioningCircuit from './AddConditioningCircuit';
import AddConditioningExercise from './AddConditioningExercise.js';
import ErrorMessage from "../../../../components/ErrorMessage.js";

const ConditioningSession = ({ user }) => {

    const userId = user.userId;
    const navigate = useNavigate();
    const location = useLocation();
    const session = location.state?.session ?? '';

    useEffect(() => {
        if (!location.state?.session) {
            navigate('/conditioning');
        }
    }, [location.state, navigate]);

    const [userCircuits, setUserCircuits] = useState([]);
    const [entries, setEntries] = useState([]);
    const [exerciseList, setExerciseList] = useState([]);
    const [state, setState] = useState('loading');
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [isError, setIsError] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);

    const emptyCircuit = {
        user_id: userId,
        session_id: session.id,
        title: "",
        sets: 1
    }
    const [circuitPlaceholder, setCircuitPlaceholder] = useState(emptyCircuit);
    
    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true); 
          }
        };
    
        fetchJwtToken();
      }, []);

    useEffect(() => {
        if (isTokenReady) {
            axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/entry/${userId}/${session.id}`)
            .then(response => {
                const data = response.data;
                if (data !== null) { 
                    setEntries(data);
                }
            })
            .catch(error => {
                console.error('Error fetching entries:', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/user-circuits/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) { 
                    setUserCircuits(data);
                }
            })
            .catch(error => {
                console.error('Error fetching user circuits:', error);
                setIsError(true);
            });

            axios.get(process.env.REACT_APP_API_HOST + `/api/conditioning/exercises/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setExerciseList(data)
                }
            })
            .catch(error => {
                console.error('Error fetching exercises:', error);
                setIsError(true);
            });

            setState('view');
        }
    }, [session, userId, isTokenReady]);

    const handleAddCircuit = (newCircuit) => {
        if (state === 'addCircuit') {
            axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions/circuits', newCircuit, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                const { last_id } = response.data;
                let newEntryWithId = {...newCircuit, id: last_id, type: 'circuit'};
                setEntries([newEntryWithId, ...entries]);
                setState('view');
            })
            .catch(error => {
                console.error('Error posting new conditioning circuit:', error);
                setIsError(true);
            });  
        } else {
            const newCircuitWithIDs = {
                ...newCircuit,
                user_id: userId,
                session_id: session.id
            }
            axios.put(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/circuits`, newCircuitWithIDs, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setEntries(entries.map(entry => {
                    if (entry.id === newCircuit.id) {
                        return newCircuit
                    }
                    return entry
                }));
                setState('view');
            })
            .catch(error => {
                console.error(`Error updating circuit details:`, error);
                setIsError(true);
            }); 
        }
    }
    
    const handlAddExercise = (newExercise) => {
        const newExerciseWithIDs = {
            ...newExercise,
            user_id: userId,
            session_id: session.id
        };
        if (state === 'addExercise') {
            axios.post(process.env.REACT_APP_API_HOST + '/api/conditioning/sessions/exercise', newExerciseWithIDs, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                const { last_id } = response.data;
                let newEntryWithId = {...newExerciseWithIDs, id: last_id, type: 'exercise'};
                setEntries([newEntryWithId, ...entries]);
                setState('view');
            })
            .catch(error => {
                console.error('Error posting conditioning session exercise:', error);
                setIsError(true);
            });  
        } else {
            axios.put(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/exercise`, newExerciseWithIDs, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setEntries(entries?.map(entry => {
                    if (entry.id === newExercise.id) {
                        return newExercise
                    }
                    return entry
                }));
                setState('view');
            })
            .catch(error => {
                console.error(`Error updating exercise entry details:`, error);
                setIsError(true);
            }); 
        }
    }

    const handleConfirmRemove = (id) => {
        setIdToDelete(id);
        setConfirmModal(true);
    }

    const handleDelete = (circuit_id) => {
        axios.delete(process.env.REACT_APP_API_HOST + `/api/conditioning/sessions/entry/${userId}/${session.id}/${circuit_id}`)
        .then(() => {
            setEntries(entries.filter(item => item.id !== circuit_id));
            setConfirmModal(false);
            setIdToDelete(0);
        })
        .catch(error => {
            console.error('Error deleting conditioning circuit', error);
            setIsError(true);
        });
    }

    const handleEditing = (entry) => {
        setCircuitPlaceholder(entry.type === 'exercise' ? emptyCircuit : entry);
        setState(entry.type === 'exercise' ? 'editExercise' : 'editCircuit');
    };

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(false);
        setIdToDelete(0);
    };

    const addCircuit = () => {
        setCircuitPlaceholder(emptyCircuit);
        setState('addCircuit');
    };

    const addExercise = () => {
        setCircuitPlaceholder(emptyCircuit);
        setState('addExercise');
    };
    
    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    if (state === 'loading' || exerciseList?.length === 0) {
        return <LoadingMessage title={'Conditioning'} />
    }

    return (
        <>
            {state === 'view' && <>

                <Box sx={{ fontSize: '40px', textAlign: 'center', height: '60px', position: 'fixed', top: '16px', left: '50%', transform: 'translateX(-50%)', width: 'calc(100% - 40px)' }}>
                    <Link to="/conditioning">
                        <IconButton style={{position: 'absolute', top: '10px', left: '0', padding: '0', fontSize: "35px", color: "var(--site-light-text-color)"}} aria-label="back" size="large" alt='back' >
                            <ArrowBackIcon fontSize="inherit" />
                        </IconButton>
                    </Link>
                </Box>
                <h2 style={{ color: 'var(--site-light-text-color)', margin: '0 35px' }}>{session.title}</h2>
                {/* <Header title={session.title} /> */}
            
                <div className='entries'>
                    {entries?.map((entry) => (
                        <ConditioningEntry key={entry.id} entry={entry} circuit={userCircuits.find(c => c.id === entry.circuit_id) ?? {}} exerciseList={exerciseList} setEditing={handleEditing} remove={handleConfirmRemove} />
                    ))} 
                </div>
            </>}

            {(state === 'addCircuit' || state === 'editCircuit') && 
                <AddConditioningCircuit circuitHolder={circuitPlaceholder} userCircuits={userCircuits.filter(c => c.status === 'active')} handleSubmit={handleAddCircuit} handleCancel={cancelEntry} />
            }

            {(state === 'addExercise' || state === 'editExercise') &&
                <AddConditioningExercise exerciseList={exerciseList.filter(ex => ex.status === 'active')} handleSubmit={handlAddExercise} handleCancel={cancelEntry} />
            }

            {/* {state === 'createCircuit' && 
                <CreateConditioningCircuit userId={userId} submitHandler={handleNewUserCircuit} handleCancel={cancelEntry} />
            }

            {state === 'createExercise' && 
                <CreateCustomExercise handleSubmit={handleNewCustomExercise} handleCancel={cancelEntry} />
            } */}
            
            {/* {(state === 'view') &&
                <ConditioningSpeedDial 
                    iconOneAction={addCircuit} 
                    iconOneText={'Add Circuit'}
                    iconTwoAction={addExercise} 
                    iconTwoText={'Add Exercise'}
                    // iconThreeAction={createExercise} 
                    // iconThreeText={'Create Exercise'}
                    // iconFourAction={createCircuit} 
                    // iconFourText={'Create Circuit'}
                />
            }     */}

            {state === 'view' && <>
                <div className='flex-add-buttons'>
                    <Button
                        style={{margin: "15px 5px 10px", width: '100px'}}
                        onClick={addExercise}
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        Exercise
                    </Button>

                    <Button
                        style={{margin: "15px 5px 10px", width: '100px'}}
                        onClick={addCircuit}
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        Circuit
                    </Button>
                </div>
            </>}

            {confirmModal && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this entry?"} handleConfirm={() => handleDelete(idToDelete)} handleCancel={cancelEntry}/>
            }
        </>
    )
}

export default ConditioningSession;