import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Typography, Box } from "@mui/material";

const PromoCodeInput = ({ handleCancel }) => {
    const [promoCode, setPromoCode] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

    const handleApply = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}/payments/discount`,
                { promo_code: promoCode }
            );
            if (response.data) {
                switch (response.data.message) {
                    case "Promo Code Applied":
                        setIsError(false);
                        setMessage("Promo Code Applied Successfully!");
                        break;
                    case "Code already applied":
                        setIsError(false);
                        setMessage("Your plan already has an active promo code");
                        break;
                    case "Invalid or Expired code":
                        setIsError(true);
                        setMessage("Code is invalid or expired.");
                        break;
                    case "Invalid Promo Code":
                        setIsError(true);
                        setMessage("Code is invalid or expired.");
                        break;
                    default:
                        break;
                }
            }
            
        } catch (error) {
            setMessage("Invalid Promo Code");
            setIsError(true);
        }
    };

    const handleCancelClick = () => {
        setPromoCode("");
        setMessage("");
        setIsError(false);
        handleCancel();
    };

    return (<>
        <div style={{position: 'fixed', top: '0', left: '0', right: 0, height: 'calc(100% - 56px)', backgroundColor: 'rgba(84, 84, 84, 0.7)'}}></div>
        <div style={{backgroundColor: '#e0e0e0', border: "2px solid #545454", borderRadius: '15px', position: 'absolute', top: '0', bottom: '10%', margin:'auto 0', height: 'fit-content', width: '100%', zIndex: '1000', padding: '10px'}}>
            
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Typography variant="h4" style={{color: "var(--site-dark-text-color)"}}>Apply Promo Code</Typography>

                {message && (
                    <Typography variant="h6" color={isError ? "error" : "success.main"}>
                        {message}
                    </Typography>
                )}
                
                <TextField
                    label="Enter Promo Code"
                    variant="outlined"
                    fullWidth
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    sx={{
                        "& .MuiInputBase-root": { height: 40 },
                    }}
                />

                <Box display="flex" gap={1}>
                    <Button variant="contained" color="primary" onClick={handleApply} disabled={promoCode.length === 0}>
                        Apply
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCancelClick}>
                        Close
                    </Button>
                </Box>
            </Box>
        </div>
        </>);
};

export default PromoCodeInput;
