import { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { IconButton, Button, Box } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddIcon from '@mui/icons-material/Add';
import AddTrampolineEntry from './components/AddTrampolineEntry';
import DiaryEntry from './components/DiaryEntry';
import HydrationTracker from './components/HydrationTracker';
import CompleteSession from './components/CompleteSession';
import AddDmtEntry from './components/AddDmtEntry';
import AddTumblingEntry from './components/AddTumblingEntry';
import DeleteConfirmationBox from "../../components/DeleteConfirmationBox";
import LoadingMessage from '../../components/LoadingMessage';
import ErrorMessage from "../../components/ErrorMessage.js";

const Diary = ({ user }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const userId = user.userId;

    useEffect(() => {
        if (!location.state?.session) {
            navigate('/diary');
        }
    }, [location.state, navigate]);

    const [state, setState] = useState('view');
    const [isTokenReady, setIsTokenReady] = useState(false);
    const [confirmModal, setConfirmModal] = useState(0);
    const [idToDelete, setIdToDelete] = useState(0);
    const [session, setSession] = useState(location.state?.session);
    const [entries, setEntries] = useState([]);
    const [objectives, setObjectives] = useState([]);
    const [isError, setIsError] = useState(false);


    const blankTrampolineEntry = {
        user_id: userId,
        session_id: session?.id,
        objective: {
            type: 'routine',
            id: '', 
            name: ''
        },
        new_skill_phase: "",
        completed: true,
        time_method: 'tof',
        total_tof: 0,
        total_hd: 0,
        execution: 0,
        total_difficulty: 0,
        feedback: null,
        self_reflection: null,
        repetitions: 1,
        total_inbounces: 8
    };

    const blankDmtEntry = {
        user_id: userId,
        session_id: session?.id,
        objective: {
            type: 'pass',
            id: '', 
            name: ''
        },
        total_skills: 2,
        total_completed_skills: 2,
        repetitions: 1,
        completed: true,
        time_method: 'stopwatch',
        total_tof: 0,
        landing_penalty: 999, // since 0 is good here, need it to be accurate for stats
        execution: 0,
        total_difficulty: 0,
        feedback: null,
        self_reflection: null
    };

    const blankTumblingEntry = {
        user_id: userId,
        session_id: session?.id,
        objective: {
            type: 'pass',
            id: '', 
            name: ''
        },
        total_skills: 8,
        total_completed_skills: 8,
        repetitions: 1,
        completed: true,
        landing_penalty: 999, // since 0 is good here, need it to be accurate for stats
        execution: 0,
        total_difficulty: 0,
        feedback: null,
        self_reflection: null
    };

    //Initially blank, update for edits
    const [entryPlaceholder, setEntryPlaceholder] = useState(() => {
        if (session?.discipline === 'trampoline') {
          return blankTrampolineEntry;
        } else if (session?.discipline === 'dmt') {
          return blankDmtEntry;
        } else {
          return blankTumblingEntry; 
        }
    });

    useEffect(() => {
        const fetchJwtToken = async () => {
          try {
            const session = await fetchAuthSession();
            const token = session.tokens.idToken;
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            setIsTokenReady(true); 
          } catch (error) {
            console.error('Error fetching JWT token:', error);
            setIsError(true);
          }
        };
    
        fetchJwtToken();
      }, []);

    useEffect(() => {
        if (isTokenReady) {

            axios.get(process.env.REACT_APP_API_HOST + `/api/sessions/${session?.discipline}/${userId}/${session?.id}`)
            .then(response => {
                const entries = response.data;
                if (entries !== null) {
                    setEntries(entries);
                }
            })
            .catch(error => {
                console.error('Error fetching all entries:', error);
                setIsError(true);
            })
                
            axios.get(process.env.REACT_APP_API_HOST + `/api/objectives/${session?.discipline}/${userId}`)
            .then(response => {
                const data = response.data;
                if (data !== null) {
                    setObjectives(data);
                }
            })
            .catch(error => {
                console.error('Error fetching objectives:', error);
                setIsError(true);
            })
        }
    }, [session, userId, isTokenReady]); 

    const handleNewEntry = (newEntry) => {
        
        if (state === 'entering') {
            axios.post(process.env.REACT_APP_API_HOST + `/api/sessions/${session?.discipline}`, newEntry, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                const { last_id } = response.data;
                const newEntryWithId = {...newEntry, id: last_id};
                setEntries([newEntryWithId, ...entries]);
                setState('view');
            })
            .catch(error => {
                console.error(`Error posting ${session?.discipline} entry details:`, error);
                setIsError(true);
            }); 
        } else {
            axios.put(process.env.REACT_APP_API_HOST + `/api/sessions/${session?.discipline}`, newEntry, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setEntries(entries.map(entry => {
                    if (entry.id === newEntry.id) {
                        return newEntry
                    }
                    return entry
                }));
                setState('view');
            })
            .catch(error => {
                console.error(`Error updating ${session?.discipline} entry details:`, error);
                setIsError(true);
            }); 
        }
    };

    const cancelEntry = () => {
        setState('view');
        setConfirmModal(0);
        setIdToDelete(0);
    };

    const handleEditing = (entry) => {
        setEntryPlaceholder(entry);
        setState('editing');
    };

    const handleConfirmRemoveEntry = (id) => {
        setIdToDelete(id);
        setConfirmModal(1);
    };

    const handleConfirmRemoveSession = () => {
        setConfirmModal(2);
    }

    const handleConfirmSessionReopen = () => {
        setConfirmModal(3);
    }

    const removeEntry = (id) => { 
        axios.delete(process.env.REACT_APP_API_HOST + `/api/sessions/${session?.discipline}/${userId}/${session?.id}/${id}`)
        .then(() => {
            setEntries(entries.filter(item => item.id !== id));
        })
        .catch(error => {
            console.error(`Error deleting ${session?.discipline} entry`, error);
            setIsError(true);
        });
        setConfirmModal(0);
        setIdToDelete(0);
    };

    const removeSession = () => {
        axios.delete(process.env.REACT_APP_API_HOST + `/api/sessions/${userId}/${session?.id}`)
        .catch(error => {
            console.error('Error deleting session entry', error);
            setIsError(true);
        });
        setConfirmModal(0);
        navigate("/");
    }

    const unlockSession = () => {
        axios.patch(process.env.REACT_APP_API_HOST + `/api/sessions/reopen/${userId}/${session?.id}`, 
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }) 
            .catch(error => {
                console.error('Error patching session details:', error);
                setIsError(true);
            }
        ); 
        setSession({...session, status: 'active'});
        setConfirmModal(0);
    };

    const addWater = () => {
        setState('water'); 
    };

    const handleWater = (water) => {
        axios.patch(process.env.REACT_APP_API_HOST + '/api/sessions/hydration', 
            {
                'user_id': userId,
                'id': parseInt(session?.id),
                'water': water
            }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(() => {
                setSession({
                    ...session,
                    hydration: session?.hydration + water
                });
            })
            .catch(error => {
                console.error(`Error patching hydration entry details:`, error);
                setIsError(true);
            }
        ); 
        
        setState('view');
    };

    const addEntry = () => {
        setEntryPlaceholder(() => {
            if (session?.discipline === 'trampoline') {
              return blankTrampolineEntry;
            } else if (session?.discipline === 'dmt') {
              return blankDmtEntry;
            } else {
              return blankTumblingEntry; 
            }
        });
        setState('entering');
    };

    const handleDuplicate = (entry) => {
        const minimisedEntry = {
            completed: entry.completed,
            objective: entry.objective,
            repetitions: entry.repetitions,
            session_id: entry.session_id,
            total_completed_skills: entry.total_completed_skills,
            total_difficulty: entry.total_difficulty,
            total_load: entry.total_load,
            total_skills: entry.total_skills,
            user_id: entry.user_id
            
        };

        axios.post(process.env.REACT_APP_API_HOST + `/api/sessions/${session?.discipline}`, minimisedEntry, 
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        .then(response => {
            const { last_id } = response.data;
            const newEntryWithId = {...minimisedEntry, id: last_id};
            setEntries([newEntryWithId, ...entries]);
        })
        .catch(error => {
            console.error('Error duplicating entry:', error);
            setIsError(true);
        }); 
    };

    const handleAddRep = (entry) => {
        axios.put(process.env.REACT_APP_API_HOST + `/api/sessions/${session?.discipline}`, 
            {
                ...entry,
                repetitions: entry.repetitions + 1
            }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ) 
        .then(() => {
            setEntries(prevEntries =>
                prevEntries.map(ent =>
                    ent.id === entry.id
                    ? { ...ent, repetitions: ent.repetitions + 1 }
                    : ent
                )
            );
        })
        .catch(error => {
            console.error('Error adding rep to entry:', error);
            setIsError(true);
        }); 
    };

    const handleCompleteSession = (finishSession) => {
        axios.patch(process.env.REACT_APP_API_HOST + '/api/sessions/complete', 
            {
                'user_id': userId,
                'id': parseInt(session?.id),
                'discipline': session?.discipline,
                'end_mood': finishSession.end_mood,
                'end_time': finishSession.end_time,
                'session_feedback': finishSession.session_feedback,
                'session_reflection': finishSession.session_reflection
            }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        ) 
        .then(() => {
            setSession({...session, status: 'closed'});
            setState('view');
        })
        .catch(error => {
            console.error('Error patching session details:', error);
            setIsError(true);
        }); 
    };
    
    if (isError) return <ErrorMessage handleConfirm={() => {setIsError(false); setState('view')}} />;

    if (!objectives?.length) {
        return (
            <LoadingMessage title={'Your Session'} />
        )
    };

    return (
        <>
            <Box sx={{ fontSize: '40px', textAlign: 'center', height: '60px', position: 'fixed', top: 0, left: '50%', transform: 'translateX(-50%)', width: 'calc(100% - 40px)', color: "var(--site-light-text-color)" }}>
                <Link to="/diary">
                    <IconButton style={{position: 'absolute', top: '15px', left: '0', padding: '0', fontSize: "40px", color: "var(--site-light-text-color)"}} aria-label="back" size="large" alt='back' >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                </Link>
                {state === 'entering' ? 'Add Entry' : 'Your Session'}

                { (entries?.length === 0 && state === 'view') &&
                    <IconButton style={{position: 'absolute', top: '15px', right: '0', padding: '0', fontSize: "35px", color: "var(--site-light-text-color)"}} aria-label="delete" size="large" alt='delete' onClick={handleConfirmRemoveSession} >
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                }
                { session?.status === 'closed' &&
                    <IconButton style={{position: 'absolute', top: '15px', right: '0', padding: '0', fontSize: "35px", color: "var(--site-light-text-color)"}} aria-label="reopen" size="large" alt='reopen' onClick={handleConfirmSessionReopen} >
                        <LockOpenIcon fontSize="inherit" />
                    </IconButton>
                }
            </Box>

            {(state === 'view' && session?.status !== 'closed' && entries?.length > 0) && 
                <Box sx={{textAlign: 'center', position: 'fixed', top: '55px', left: '50%', transform: 'translateX(-50%)', height: '33px', width: 'calc(100% - 40px)'}}>
                    <Button variant="contained" style={{fontSize: '12px', color: 'var(--site-dark-text-color)', backgroundColor: '#83FFE7'}} endIcon={<DoneIcon />}  onClick={() => {setState('finish')}}>Finish session</Button>
                </Box>
            }

            {(state === 'entering' || state === 'editing') && <> 
                {session?.discipline === 'trampoline' &&
                    <AddTrampolineEntry 
                        objectives={objectives}
                        entryHolder={entryPlaceholder} 
                        handleNewEntry={handleNewEntry} 
                        handleCancel={cancelEntry}
                        isDisabled={session?.status === 'closed'}
                    />
                }
                {session?.discipline === 'dmt' &&
                    <AddDmtEntry 
                        objectives={objectives}
                        entryHolder={entryPlaceholder} 
                        handleNewEntry={handleNewEntry} 
                        handleCancel={cancelEntry}
                        isDisabled={session?.status === 'closed'}
                    />
                }
                {session?.discipline === 'tumbling' &&
                    <AddTumblingEntry 
                        objectives={objectives}
                        entryHolder={entryPlaceholder} 
                        handleNewEntry={handleNewEntry} 
                        handleCancel={cancelEntry}
                        isDisabled={session?.status === 'closed'}
                    />
                }
            </>}

            {(state === 'view' && entries?.length > 0) &&
                <div className='entries'>
                    {entries?.map((entry, index) => (
                        <DiaryEntry 
                            key={entry.id} 
                            sessionStatus={session?.status} 
                            entry={entry} 
                            discipline={session?.discipline}
                            index={entries.length - index} 
                            remove={handleConfirmRemoveEntry} 
                            setEditing={handleEditing} 
                            addRep={handleAddRep}
                            duplicate={handleDuplicate}
                        />
                    ))}
                </div>
            }

            {confirmModal === 1 && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this entry?"} handleConfirm={() => removeEntry(idToDelete)} handleCancel={cancelEntry}/>
            }

            {confirmModal === 2 && 
                <DeleteConfirmationBox message={"Are you sure you want to delete this session?"} handleConfirm={() => removeSession(session?.id)} handleCancel={cancelEntry}/>
            }

            {confirmModal === 3 && 
                <DeleteConfirmationBox message={"Are you sure you want to re-open this session?"} handleConfirm={() => unlockSession(session?.id)} handleCancel={cancelEntry}/>
            }

            {(state === 'view' && session?.status !== 'closed') && (
                <div className="flex-add-buttons">
                    <Button
                        style={{margin: "15px 5px 10px", width: '100px'}}
                        onClick={addEntry}
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        Entry
                    </Button>

                    <Button
                        style={{margin: "15px 5px 10px", width: '100px'}}
                        onClick={addWater}
                        variant="contained"
                        startIcon={<AddIcon />}
                    >
                        Water
                    </Button>
                </div>
            )}


            {state === 'water' &&
                <HydrationTracker 
                    current={session?.hydration} 
                    handleSave={handleWater} 
                    handleCancel={cancelEntry} 
                />
            } 

            {state === 'finish' &&
                <CompleteSession startTime={session?.session_date}
                    handleCompleteSession={handleCompleteSession} 
                    handleCancel={cancelEntry} 
                />
            } 
    </>
    )
};

export default Diary;