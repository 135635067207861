import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";
import { Button, Card, CardContent, Typography, Grid, Box, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

const SelectSubscription = ({ signOut }) => {
    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [referralCode, setReferralCode] = useState("");
    const [isTokenReady, setIsTokenReady] = useState(false);

    useEffect(() => {
        const fetchJwtToken = async () => {
            try {
                const session = await fetchAuthSession();
                const token = session.tokens.idToken;
                axios.defaults.headers["Authorization"] = `Bearer ${token}`;
                setIsTokenReady(true);
            } catch (error) {
                console.error("Error fetching JWT token:", error);
                setError(true);
            }
        };

        fetchJwtToken();
    }, []);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_HOST}/payments/prices`
                );

                let fetchedPrices = response.data;
                const monthlyPlan = fetchedPrices.find(p => p.period === "monthly");
                const monthlyPricePerMonth = monthlyPlan ? monthlyPlan.price / 100 : null;

                fetchedPrices = fetchedPrices
                    .map(price => {
                        const months =
                            price.period === "monthly" ? 1 :
                            price.period === "quarterly" ? 3 :
                            price.period === "half-yearly" ? 6 :
                            price.period === "yearly" ? 12 : 1;

                        const unitPrice = price.price / 100 / months;
                        const discount =
                            monthlyPricePerMonth && price.period !== "monthly"
                                ? ((1 - unitPrice / monthlyPricePerMonth) * 100).toFixed(0)
                                : null;

                        return { ...price, unitPrice, discount };
                    })
                    .sort((a, b) => a.price - b.price);

                setPrices(fetchedPrices);
            } catch (err) {
                setError("Failed to fetch subscription options.");
            } finally {
                setLoading(false);
            }
        };

        if (isTokenReady) {
            fetchPrices();
        }
    }, [isTokenReady]);

    const createCheckout = async (priceId, referralCode = "") => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_HOST}/payments/checkout-session`,
                { price_id: priceId }
            );

            const { subscriptionStatus, checkoutSessionUrl } = response.data;

            if (subscriptionStatus === "active") {
                console.log("Subscription already active.");
            } else {
                window.location.href = checkoutSessionUrl;
            }
        } catch (err) {
            console.error("Failed to create checkout session.", err);
        }
    };

    const handleSignout = () => {
        localStorage.removeItem('jwtToken'); // Remove from localStorage
        axios.defaults.headers['Authorization'] = ''; // Clear  Axios header
        signOut();
      };  

    if (loading) return <Typography>Loading subscription options...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box display="flex" justifyContent="center" padding={2} sx={{ height: "calc(100vh - 56px)", overflowY: "auto" }}>
            <IconButton style={{position: 'absolute', top: '18px', right: '16px', padding: '0', fontSize: "30px", color: "var(--site-light-text-color)"}} aria-label="delete" size="large" alt='delete' onClick={() => handleSignout()} >
              <LogoutIcon fontSize="inherit" />
            </IconButton>
            <Box maxWidth={400} width="100%">
                {/* Page Title */}
                <Typography variant="h3" gutterBottom textAlign="center" sx={{ color: "var(--site-light-text-color)", mb: 2 }}>
                    Select Subscription
                </Typography>

                <Grid container spacing={2}>
                    {prices.filter(price => price.price === 0).map((price) => (
                        <Grid item xs={12} key={price.price_id}>  {/** maybe Change back to width 6 before launch*/}
                            <Card style={{ height: "100%" }}>
                                <CardContent
                                    style={{
                                        textAlign: "center",
                                        backgroundColor: "#83FFE7",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        height: "100%",
                                    }}
                                >
                                    <Typography variant="h5" gutterBottom>
                                        {price.period
                                            .replace("monthly", "Monthly")
                                            .replace("quarterly", "Quarterly")
                                            .replace("half-yearly", "Half Yearly")
                                            .replace("yearly", "Annual")}
                                    </Typography>
                                    <Typography variant="h6">£{price.price / 100}</Typography>
                                    {price.discount && (
                                        <Typography variant="body1" color="dark-grey">
                                            Save {price.discount}% compared to Monthly
                                        </Typography>
                                    )}
                                    <Button
                                        variant="contained"
                                        style={{
                                            marginTop: "10px",
                                            backgroundColor: "var(--site-background-color)",
                                            color: "#FFFFFF",
                                        }}
                                        onClick={() => createCheckout(price.price_id)}
                                    >
                                        Select
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}

                    {/* Club Subscription Option */}
                    {/* <Grid item xs={12} sm={12}>
                        <Card style={{ height: "100%" }}>
                            <CardContent
                                style={{
                                    textAlign: "center",
                                    backgroundColor: "#83FFE7",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    height: "100%",
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Club Subscription
                                </Typography>
                                <Typography variant="body1" color="dark-grey">
                                    If your club has given you a subscription, please enter your code:
                                </Typography>
                                <TextField
                                    label="Referral Code"
                                    variant="outlined"
                                    fullWidth
                                    value={referralCode}
                                    onChange={(e) => setReferralCode(e.target.value)}
                                    style={{ margin: "10px 0" }}
                                    sx={{
                                        "& .MuiInputBase-root": { height: 36 }, // Controls the outer box height
                                        "& .MuiInputBase-input": { padding: "6px 10px", fontSize: "12px" }, // Controls text size and padding
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: "var(--site-background-color)",
                                        color: "#FFFFFF",
                                    }}
                                    onClick={() =>
                                        createCheckout("club_subscription_price_id", referralCode)
                                    }
                                >
                                    Select
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    );
};

export default SelectSubscription;
